import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../constants/index"

export const login = createAsyncThunk('auth/login', async (credentials, rejectWithValue) => {
    try {
        const response = await axios.post(apiUrl.LOGIN_URL, credentials);
        const { accessToken, refreshToken } = response.data.tokens;
        const { email, team } = response.data.user;
        const userRole = response.data.user.user_role;
        return { accessToken, refreshToken, userRole, email, team };
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
})

export const refreshAccessToken = createAsyncThunk(
    'auth/refreshAccessToken',
    async (_, { getState, rejectWithValue }) => {
      const state = getState();
      const refreshToken = state.auth.refreshToken;
  
      if (!refreshToken) {
        return rejectWithValue('No refresh token available');
      }
  
      try {
        const response = await axios.post(apiUrl.REFRESH_TOKEN_URL, { refreshToken });
        const { accessToken, newRefreshToken } = response.data;
        return { accessToken, refreshToken: newRefreshToken || refreshToken };
      } catch (error) {
        return rejectWithValue('Refresh token is expired or invalid');
      }
    }
);

export const authSlice = createSlice({
    "name": 'auth',
    initialState: {
        accessToken: null,
        refreshToken: null,
        isAuthenticated: false,
        status: 'idle', // idle | loading | succeeded | failed
        error: null,
        role: null,
        email: null,
        team: null
        
    },
    reducers: {
        logout: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
            state.isAuthenticated = false;
            state.role = null;
            state.email = null;
            state.team = null;
        },
        setTeam: (state, action) => {
            state.team = action.payload;
          },
    },
    extraReducers: (builder) => {
        builder
        .addCase(login.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(login.fulfilled, (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.isAuthenticated = true;
            state.status = 'succeeded';
            state.role = action.payload.userRole;
            state.email = action.payload.email;
            state.team = action.payload.team;
        })
        .addCase(login.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.payload
        })
        .addCase(refreshAccessToken.fulfilled, (state, action) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.isAuthenticated = true;
            state.status = 'succeeded';
        })
        .addCase(refreshAccessToken.rejected, (state, action) => {
        state.isAuthenticated = false;
        state.status = 'failed';
        state.error = action.payload;
        });
    }
})

export const { logout, setTeam } = authSlice.actions;

export default authSlice.reducer;